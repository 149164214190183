@use 'sass:map';
@use 'sass:meta';

@mixin heading($i, $args...) {
    $params: meta.keywords($args);

    font-size: map.get($heading-mobile-sizes, h#{$i});
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;

    @if map.has-key($params, 'font') {
        $font: map.get($params, 'font');

        @include font-family($font);

        @if $font == 'serif' or $font == 'secondary' {
            letter-spacing: $heading-letter-spacing-serif;
        }
    } @else {
        @include font-family('heading');
    }

    @include mq('large') {
        font-size: map.get($heading-desktop-sizes, h#{$i});
    }

    &:is(.font__secondary, .font__serif) {
        letter-spacing: $heading-letter-spacing-serif;
    }

    :lang(ja) & {
        font-size: map.get($heading-mobile-sizes-jp, h#{$i});
        line-height: 1.35;

        @include mq('large') {
            font-size: map.get($heading-desktop-sizes-jp, h#{$i});
        }
    }
}

/* h1 */

@mixin h1($args...) {
    @include heading(1, $args...);
}

@mixin display-1($args...) {
    @include h1($args...);
}

@mixin display1($args...) {
    @include display-1($args...);
}

/* h2 */

@mixin h2($args...) {
    @include heading(2, $args...);
}

@mixin display-2($args...) {
    @include h2($args...);
}

@mixin display2($args...) {
    @include display-2($args...);
}

/* h3 */

@mixin h3($args...) {
    @include heading(3, $args...);
}

@mixin display-3($args...) {
    @include h3($args...);
}

@mixin display3($args...) {
    @include display-3($args...);
}

/* h4 */

@mixin h4($args...) {
    @include heading(4, $args...);
}

@mixin display-4($args...) {
    @include h4($args...);
}

@mixin display4($args...) {
    @include display-4($args...);
}

/* h5 */

@mixin h5($args...) {
    @include heading(5, $args...);
}

@mixin display-5($args...) {
    @include h5($args...);
}

@mixin display5($args...) {
    @include display-5($args...);
}

/* h6 */

@mixin h6($args...) {
    @include heading(6, $args...);
}

@mixin title1($args...) {
    @include h6($args...);
}

@mixin title-1($args...) {
    @include title1($args...);
}

/* h7 */

@mixin h7($args...) {
    @include heading(7, $args...);
}

@mixin title2($args...) {
    @include h7($args...);
}

@mixin title-2($args...) {
    @include title2($args...);
}

/* h8 */

@mixin h8($args...) {
    @include heading(8, $args...);
}

@mixin title3($args...) {
    @include h8($args...);
}

@mixin title-3($args...) {
    @include title3($args...);
}

/* h9 */

@mixin h9($args...) {
    @include heading(9, $args...);
}

@mixin title4($args...) {
    @include h9($args...);
}

@mixin title-4($args...) {
    @include title4($args...);
}

/* h10 */

@mixin h10($args...) {
    @include heading(10, $args...);
}

@mixin title5($args...) {
    @include h10($args...);
}

@mixin title-5($args...) {
    @include title5($args...);
}

@mixin jumbo($i, $args...) {
    $params: meta.keywords($args);

    font-size: map.get($jumbo-mobile-sizes, jumbo#{$i});
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;

    @if map.has-key($params, 'font') {
        $font: map.get($params, 'font');

        @include font-family($font);

        @if $font == 'serif' or $font == 'secondary' {
            letter-spacing: $heading-letter-spacing-serif;
        }
    } @else {
        @include font-family('heading');
    }

    @include mq('large') {
        font-size: map.get($jumbo-desktop-sizes, jumbo#{$i});
    }

    &:is(.font__secondary, .font__serif) {
        letter-spacing: $heading-letter-spacing-serif;
    }

    :lang(ja) & {
        font-size: map.get($jumbo-mobile-sizes-jp, jumbo#{$i});
        line-height: 1.35;

        @include mq('large') {
            font-size: map.get($jumbo-desktop-sizes-jp, jumbo#{$i});
        }
    }
}

/* jumbo1 */

@mixin jumbo1($args...) {
    @include jumbo(1, $args...);
}

@mixin jumbo-1($args...) {
    @include jumbo1($args...);
}

/* jumbo2 */

@mixin jumbo2($args...) {
    @include jumbo(2, $args...);
}

@mixin jumbo-2($args...) {
    @include jumbo2($args...);
}
