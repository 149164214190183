@import './../../base-bootstrap';
@import 'animation';

// from js/modules/hero/hero-main/style.scss
.hero-main {
    $this: &;
    $btn-horz-margin: $spacer;
    $max-content-width: 70rem;
    $max-smaller-text-width: 42rem;

    position: relative;

    display: grid;

    width: 100%;
    height: auto;

    overflow: hidden;

    ////////////////////////////////////////////////////////////////
    // VARIATIONS

    &.is-info-delayed {
        #{$this}__bottom,
        #{$this}__overlay {
            transition-delay: 0.4s;
        }
    }

    &.is-no-bg {
        background-color: transparent;

        #{$this}__bg,
        #{$this}__overlay,
        #{$this}__video-overlay {
            visibility: hidden;
        }
    }

    &--off-image-left,
    &--off-image-right {
        @include mq('large') {
            display: grid;
            grid-template-columns: 50% 50%;
        }
    }

    &--off-image-left {
        @include mq('large') {
            #{$this}__foreground-container {
                order: -1;

                padding-right: $spacer * 5;
                padding-left: $spacer * 10;
            }
        }

        @include mq('xlarge') {
            grid-template-columns: 40% 60%;
        }
    }

    &--off-image-right {
        @include mq('large') {
            #{$this}__foreground-container {
                padding-right: $spacer * 10;
                padding-left: $spacer * 5;
            }
        }

        @include mq('xlarge') {
            grid-template-columns: 60% 40%;
        }
    }

    &__content {
        opacity: 1;
        transform: translateY(0);

        transition:
            opacity 0.6s cubic-bezier(0.38, 0.41, 0.27, 1),
            transform 0.6s cubic-bezier(0.38, 0.41, 0.27, 1);
        transition-delay: 0.4s;

        &.is-hidden {
            opacity: 0;
            transform: translateY(6rem);

            pointer-events: none;
            will-change: opacity, transform;

            transition-delay: 0s;
        }
    }

    &__center-center,
    &__top-center,
    &__bottom-center {
        #{$this}__inner,
        #{$this}__foreground-container {
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            text-align: center;
        }

        #{$this}__content-wrapper {
            margin: 0 auto;

            text-align: center;
        }

        #{$this}__svg-wrapper {
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__top-center {
        #{$this}__inner,
        #{$this}__foreground-container {
            justify-content: flex-start;
        }
    }

    &__bottom-center {
        #{$this}__inner,
        #{$this}__foreground-container {
            justify-content: flex-end;
        }
    }

    &__top-left,
    &__center-left,
    &__bottom-left {
        // Remove grid offset since we're aligning left
        .offset-md-2,
        .offset-lg-2,
        .offset-md-3,
        .offset-lg-3 {
            margin-left: 0;
        }

        h2.text-center {
            text-align: left !important;
        }

        #{$this}__svg-wrapper {
            margin-right: 0;
            margin-left: 0;
        }

        #{$this}__eyebrow,
        #{$this}__subhead {
            margin-left: 0;
        }
    }

    &__top-left {
        #{$this}__inner,
        #{$this}__foreground-container {
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;

            text-align: left;

            .offset-md-1 {
                margin-left: 0;
            }
        }

        #{$this}__cta-wrapper {
            justify-content: flex-start;

            text-align: left;
        }

        #{$this}__svg-wrapper--inline #{$this}__svg {
            object-position: left center;

            @include mq('medium', 'min') {
                object-position: left;
            }
        }
    }

    &__center-left {
        #{$this}__inner,
        #{$this}__foreground-container {
            flex-flow: column nowrap;
            justify-content: center;
            align-items: flex-start;

            text-align: left;

            .offset-md-1 {
                margin-left: 0;
            }
        }

        #{$this}__cta-wrapper {
            justify-content: flex-start;

            text-align: left;
        }

        #{$this}__svg-wrapper--inline #{$this}__svg {
            object-position: left center;

            @include mq('medium', 'min') {
                object-position: left;
            }
        }
    }

    &__bottom-left {
        #{$this}__inner,
        #{$this}__foreground-container {
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: flex-start;

            text-align: left;

            .offset-md-1 {
                margin-left: 0;
            }
        }

        #{$this}__cta-wrapper {
            justify-content: flex-start;

            text-align: left;
        }
    }

    &__height-auto {
        height: unset;
        min-height: 484px;
        max-height: unset;

        @include mq('medium', 'max') {
            min-height: 370px;
        }

        #{$this}__inner-container {
            position: unset !important;
        }
    }

    &__height-100 {
        &,
        .hero-main__image-container {
            min-height: calc(
                max(540px, calc(var(--vh, 1vh) * 100)) - var(--pat-top-nav-items-height)
            );
            max-height: unset;

            @include mq('medium', 'max') {
                min-height: 375px;
            }

            .storytelling-scroller & {
                min-height: max(540px, calc(var(--vh, 1vh) * 100));
            }
        }

        @include mq('medium', 'min') {
            &:not([class*='hero-main__bottom-']) {
                #{$this}__caret-wrapper {
                    display: block;
                }
            }
        }
    }

    &__height-66 {
        .hero-main__image-container {
            min-height: 375px;

            @include mq('medium') {
                min-height: max(540px, calc(var(--vh, 1vh) * 66));
            }
        }
    }

    // slim hero for content assets, folder heros, and page designer
    &__height-slim {
        .hero-main__image-container {
            height: 44rem;
            min-height: unset;
        }
    }

    &.is-top {
        #{$this}__overlay-info {
            height: 100%;

            &.is-shown {
                z-index: 20;
            }
        }

        #{$this}__video-container {
            position: absolute;
            top: 0;
            left: 0;
        }

        #{$this}__inner {
            position: relative;
            top: auto;
            left: auto;
        }

        #{$this}__inner.align-center {
            justify-content: center;
            align-items: center;
        }
    }

    &.card-hero {
        min-height: unset;

        &.is-top {
            #{$this}__inner-container {
                padding-top: 14.8rem;

                @include mq('medium', 'max') {
                    padding: 14.8rem 0 0;
                }

                @include mq('medium', 'min') {
                    padding: 16rem 0 0;
                }
            }
        }

        .slider-cards {
            padding: 8rem 0 3.2rem;

            @include mq('medium', 'max') {
                padding: 2.4rem 0;

                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }

                .slider-cards__container {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }

            &.layout-wide {
                padding: 5.6rem 0;
            }
        }

        // squeeze container
        #{$this}__content {
            max-width: 1040px;
            margin: 4.8rem auto 0;
        }

        // Capture slider tiles
        .slider {
            [class*='col-']:not(:first-of-type) {
                display: unset;
            }
        }
    }

    #{$this}__info-open-wrapper {
        background-color: var(--pat-theme-cta-fore, $white);

        svg {
            color: var(--pat-theme-cta-back, $black);
        }

        svg:not(.colored) {
            * {
                &:not(.colored) {
                    fill: var(--pat-theme-cta-back, $black);
                }
            }
        }
    }

    #{$this}__content,
    #{$this}__overlay-info-content {
        color: var(--pat-theme-fore, $black);
    }

    #{$this}__caret svg {
        fill: var(--pat-theme-fore, $black);
    }

    #{$this}__overlay-info-blurb,
    #{$this}__overlay-info-caption {
        color: var(--pat-theme-cta-back, $black);
    }

    #{$this}__overlay-info-content {
        background-color: var(--pat-theme-cta-fore, $white);
    }

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    [class*='col-']:not(:first-of-type) {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &__image-container {
        position: relative;

        display: flex;
    }

    &__inner-container {
        width: 100%;
    }

    &__inner {
        position: relative;
        z-index: 2;

        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;

        height: 100%;

        @include mq('medium', 'max') {
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
        }

        @include mq('medium') {
            &.align-left,
            &.align-right {
                align-items: center;

                .hero-main__bottom {
                    margin-bottom: 0;
                }
            }
        }

        &.align-center {
            align-items: center;

            .hero-main__bottom {
                margin-bottom: 0;

                &--container {
                    flex: 0 0 100%;

                    max-width: none;

                    text-align: center;
                }
            }

            .hero-main__cta-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include mq('medium') {
                    flex-direction: row;
                }
            }
        }

        &.align-right {
            align-items: right;

            .hero-main__bottom {
                margin-bottom: 0;

                &--container {
                    flex: 0 0 100%;

                    max-width: none;

                    text-align: right;
                }
            }

            .hero-main__cta-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                @include mq('medium') {
                    flex-direction: row;
                }
            }
        }
    }

    &__bg {
        @include is-absolute-fs;
        z-index: 0;

        background-repeat: no-repeat;

        opacity: 1;

        transition: transform 1s $ease-out-zoom;

        &--src,
        &--src + .ls-blur-up-img {
            @include object-fit(cover);
            position: absolute;
            top: 0;
            left: 0;

            display: block;

            width: 100%;
            height: 100%;
        }

        &--full,
        &--cover {
            background-size: cover !important;
        }

        &--contain {
            background-size: contain !important;

            &.hero-main__bg--src,
            &.hero-main__bg--src + .ls-blur-up-img {
                @include object-fit(contain);
            }
        }

        &.is-zoomed {
            transform: scale(1.0625);
        }
    }

    &__overlay,
    &__video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        background: $black;

        transition: opacity 0.4s $ease-out-soft;

        @media (prefers-reduced-motion) {
            transition: none;
        }
    }

    &__overlay {
        background: var(--pat-theme-cta-back, $black);

        opacity: 0;

        #{$this}--on-image & {
            opacity: $overlay-opacity;
        }

        @include mq('medium', 'max') {
            &.story-film-overlay,
            &.story-general-overlay {
                visibility: visible !important;

                opacity: $overlay-opacity * 1.5 !important;

                transition: opacity 0s $ease-out-soft !important;

                @media (prefers-reduced-motion) {
                    transition: none;
                }
            }
        }

        &.is-dim {
            opacity: $overlay-opacity * 1.5;
        }

        &.is-hidden,
        &.story-film-overlay,
        &.story-general-overlay {
            opacity: 0;

            transition: opacity 0s $ease-out-soft;

            @media (prefers-reduced-motion) {
                transition: none;
            }
        }
    }

    &__video-overlay {
        background: $white;

        opacity: 1;
        opacity: $overlay-opacity;

        transition: opacity 2s $ease-fade;

        @media (prefers-reduced-motion) {
            transition: none;
        }

        &.is-hidden {
            opacity: 0;
        }
    }

    #{$this}__foreground-container {
        display: flex;
    }

    #{$this}__inner {
        padding-top: $spacer * 3;
        padding-bottom: $spacer * 3;

        @include mq('large') {
            padding-top: $spacer * 8;
            padding-bottom: $spacer * 8;
        }
    }

    &--on-image {
        #{$this}__inner {
            padding-top: $spacer * 7;
            padding-bottom: $spacer * 7;

            @include mq('medium') {
                padding-top: $spacer * 10;
                padding-bottom: $spacer * 10;
            }
        }
    }

    &__content-wrapper {
        max-width: $max-content-width;
        box-sizing: content-box;
    }

    &__headline {
        margin: $spacer * 0.5 auto;
    }

    &__eyebrow {
        @include h8;
        margin: $spacer * 0.5 auto;
    }

    &__subhead {
        @include p-sm;

        margin: $spacer * 0.5 auto;

        @include mq('medium') {
            @include p;
        }
    }

    &__caret-wrapper {
        position: absolute;
        left: 50%;
        bottom: 6em;
        z-index: 10;

        display: none;

        transform: translateX(-50%);

        @include mq('medium', 'max') {
            bottom: 3rem;
        }
    }

    &__caret {
        width: 2.4rem;
        height: 2.4rem;
        margin: 0 auto;

        line-height: 2.4rem;
        text-align: center;

        cursor: pointer;

        opacity: 1;
        transform: translateY(0);

        transition:
            opacity 0.6s $ease-out-soft,
            transform 0.6s $ease-out-soft;
        transition-delay: 0.4s;

        svg {
            animation: fade_move_down 2s ease-in-out infinite;
        }

        &.is-hidden {
            opacity: 0;
            transform: translateY(6rem);

            pointer-events: none;
            will-change: opacity, transform;

            transition-delay: 0s;
        }

        @include focus-visible {
            outline: $blue-utility solid 4px;
        }
    }

    &__svg-wrapper {
        width: 16rem;
        margin: $spacer * 0.5 0;

        text-align: left;

        @include mq('large') {
            width: 20rem;
        }

        &--cover,
        &--contain {
            position: absolute;
            inset: 0;

            width: 100%;
            height: 100%;
            margin: 0;

            #{$this}__svg,
            .lottie-animation,
            .lottie-animation .animation {
                position: absolute;
                inset: 0;

                height: 100%;
                max-height: 100%;

                // if not inline, always center
                object-position: center;
            }
        }

        &--cover {
            #{$this}__svg {
                @include object-fit(cover);
            }
        }
    }

    &__svg {
        @include object-fit(contain);
        width: 100%;
        max-height: 10rem;

        object-position: center;

        @include mq('large') {
            max-height: 20rem;
        }
    }

    &__cta-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        margin: $spacer ($btn-horz-margin * -0.5) 0;

        @include mq('large') {
            margin-top: $spacer * 2;
        }

        .btn {
            min-width: 0;
            margin: $spacer($btn-horz-margin * 0.5) 0;
        }
    }

    &__info-open-wrapper {
        position: absolute;
        bottom: $spacer * 2;
        right: $spacer * 2;
        z-index: 9;

        display: block;

        border-radius: 4rem;

        background-color: $light-gray;

        opacity: 1;

        transition: opacity 0.2s $ease-out-soft 0.2s;

        @media (prefers-reduced-motion) {
            transition: none;
        }

        #{$this}--on-image & {
            bottom: $spacer * 3;

            @include mq('medium') {
                right: calc(var(--bs-gutter-x) * 3);
            }

            @include mq('large') {
                right: calc(var(--bs-gutter-x) * 4);
            }

            @include mq('xlarge') {
                right: calc(var(--bs-gutter-x) * 5);
            }
        }

        &.is-hidden {
            opacity: 0;

            transition: opacity 0.2s $ease-out-soft 0.2s;
        }

        @include focus-visible {
            outline: $blue-utility solid 4px;
        }
    }

    &__info-open {
        width: 3.2rem;
        height: 3.2rem;
        padding: 0;

        color: var(--pat-theme-cta-fore, $white);

        cursor: pointer;

        transition: transform 0.2s $ease-out-soft;

        @media (prefers-reduced-motion) {
            transition: none;
        }

        /* Resize nested svg for 32px circle container */
        svg {
            left: 50%;

            max-width: 1.6rem;

            fill: var(--pat-theme-cta-back, $black);
            transform: translateX(-50%);
        }
    }

    &__info-close {
        position: absolute;
        top: auto;
        bottom: $spacer * 2;
        right: $spacer * 2;

        display: none;

        width: 3.2rem;
        height: 3.2rem;

        #{$this}--on-image & {
            bottom: $spacer * 3;

            @include mq('medium') {
                right: calc(var(--bs-gutter-x) * 3);
            }

            @include mq('large') {
                right: calc(var(--bs-gutter-x) * 4);
            }

            @include mq('xlarge') {
                right: calc(var(--bs-gutter-x) * 5);
            }
        }

        &.is-shown {
            display: block;
        }

        .cta-circle {
            height: 3.2rem;
            min-width: 3.2rem;

            svg {
                width: 1rem;
                height: 1rem;

                fill: var(--pat-theme-cta-back, $black) !important;

                transition: transform 0.2s $ease-out-soft;
            }

            &:hover {
                transform: scale(1);

                svg {
                    transform: scale(1.05);
                }
            }
        }
    }

    &__overlay-info {
        @include font-family('sans');

        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 3;

        display: flex;
        align-items: flex-end;

        width: 100%;
        height: 100%;
        padding-bottom: $spacer * 2;

        #{$this}--on-image & {
            padding-bottom: $spacer * 3;
        }

        opacity: 0;

        pointer-events: none;

        // STATES

        &.is-shown {
            $delay: 0.4s;
            z-index: 20;

            opacity: 1;

            pointer-events: auto;

            transition: opacity 0.1s $ease-out-soft;
            transition-delay: $delay;

            @media (prefers-reduced-motion) {
                transition: none;
            }

            #{$this}__overlay-info-content {
                opacity: 1;
                transform: scale(1, 1);

                pointer-events: auto;

                transition:
                    transform 0.6s $ease-out-soft,
                    opacity 0.2s $ease-out-soft;
                transition-delay: $delay;

                @media (prefers-reduced-motion) {
                    transition: none;
                }
            }

            #{$this}__overlay-info-blurb,
            #{$this}__overlay-info-caption {
                opacity: 1;
                transform: none;

                transition:
                    opacity 0.6s $ease-out-soft,
                    transform 0.8s $ease-out-soft;
                transition-delay: $delay;

                @media (prefers-reduced-motion) {
                    transition: none;
                }
            }
        }

        &:not(.is-shown) {
            visibility: hidden;
        }

        // ELEMENTS

        #{$this}__overlay-info-content-wrapper {
            position: relative;

            #{$this}:not(#{$this}--on-image) & {
                padding-right: var(--bs-gutter-x);
            }
        }

        #{$this}__overlay-info-content {
            width: calc(100% - ($spacer * 2));
            padding: $spacer * 2;
            padding-right: $spacer * 3;

            border-radius: 1.6rem;

            opacity: 0;
            transform: translateX($spacer) scale(0.1, 0.1);
            transform-origin: 95% 98%;

            pointer-events: none;

            transition:
                transform 0.6s $ease-out-soft,
                opacity 0.3s $ease-out-soft;

            @media (prefers-reduced-motion) {
                transition: none;
            }

            @include mq('medium') {
                width: 100%;
                max-width: 34.3rem;
                margin-right: $spacer;
            }

            #{$this}--on-image & {
                @include mq('medium') {
                    margin-right: $spacer * 3;
                }

                @include mq('large') {
                    margin-right: $spacer;
                }
            }
        }

        #{$this}__overlay-info-blurb,
        #{$this}__overlay-info-caption {
            @include p-sm;

            position: relative;

            display: block;

            color: var(--pat-theme-cta-back, $black);

            opacity: 0;
            transform: translateY(3rem);

            transition:
                opacity 0.4s $ease-out-soft 0s,
                transform 0s $ease-out-soft 0.4s;

            @media (prefers-reduced-motion) {
                transition: none;
            }

            > p {
                @include p-sm;
            }
        }

        #{$this}__overlay-info-blurb + #{$this}__overlay-info-caption {
            margin-top: $spacer;
        }
    }

    &__video-container {
        position: relative;
        z-index: 0;

        width: 100%;
        height: 100%;
        padding: 0;

        background: none;

        overflow: hidden;

        // background: url('../../assets/images/home/home-video-fallback.jpg') center/cover no-repeat;

        @include mq('medium') {
            background: none;
        }

        #{$this}__height-100 & {
            min-height: calc(calc(var(--vh, 1vh) * 100) + 2px);
        }

        video {
            @include object-fit(cover);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            width: 100%;
            height: 100%;
        }
    }

    &--no-caret {
        #{$this}__caret-wrapper {
            display: none !important;
        }
    }

    &--no-bg-overlay {
        #{$this}__overlay {
            display: none;
        }
    }
}
