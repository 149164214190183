@import './mixins/breakpoints';
@import './animation-variables';

// pdp 'peeking' animations

@keyframes slide-left {
    0% {
        transform: none;
    }

    30% {
        transform: translateX(-75%);
    }

    70% {
        transform: translateX(-75%);
    }

    100% {
        transform: none;
    }
}

@keyframes slide-left-full {
    0% {
        transform: none;
    }

    30% {
        transform: translateX(-100%);
    }

    70% {
        transform: translateX(-100%);
    }

    100% {
        transform: none;
    }
}

@keyframes slide-left-counter {
    0% {
        transform: none;
    }

    40% {
        transform: translateX(2rem);
    }

    60% {
        transform: translateX(2rem);
    }

    100% {
        transform: none;
    }
}

@keyframes slide-right-counter {
    0% {
        transform: none;
    }

    40% {
        transform: translateX(-2rem);
    }

    60% {
        transform: translateX(-2rem);
    }

    100% {
        transform: none;
    }
}

@keyframes btn-error {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    20%,
    60% {
        transform: translate3d(15px, 0, 0);
    }

    40%,
    80% {
        transform: translate3d(-15px, 0, 0);
    }
}
