@use 'sass:map';
@use 'sass:list';

@mixin focus-visible() {
    &:focus-visible {
        @content;
    }

    .focus-visible-unsupported &:focus {
        @content;
    }
}
